<template>
    <section class="hero header-img fill-height">
        <v-responsive>
            <v-img
              gradient="to top, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)"
              :position="img === 'profile.jpg' ? '50% 25%' : 'center center'"
              :height="height"
              :src="require(`@/assets/img/headers/${img}`)">
                <slot v-if="!title" />
                <v-layout v-else-if="title" justify-center align-center fill-height>
                    <h1 class="mb-5 text-xs-center" :class="$vuetify.breakpoint.mdAndUp ? 'display-3 font-weight-thin' : 'display-1 font-weight-light'">{{ title }}</h1>
                </v-layout>
            </v-img>
        </v-responsive>
    </section>
</template>

<script>
export default {
    props: {
        img: {
            type: String,
            default: 'coding.jpg'
        },
        title: {
            type: String,
            default: ''
        },
        large: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        height() {
          const { mdAndDown, height, width } = this.$vuetify.breakpoint
          return this.large ? '120vh' : (mdAndDown && width > height) ? '60vh' : '40vh'
        }
    }
}
</script>
